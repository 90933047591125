import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import { UserProvider } from "./context/UserContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import FireWorks from "./components/Layout/Core/Fireworks";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <UserProvider>
    <GoogleOAuthProvider clientId="758472583086-0k0bqcvlj4tlvnk13p1e6rck5sa53023.apps.googleusercontent.com">
      <FireWorks/>
      <App />
    </GoogleOAuthProvider>
  </UserProvider>
);