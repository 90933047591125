import { Fireworks } from "fireworks-js";
import { useEffect, useState } from "react";

const FireWorks = () => {
  const [active, setIsActive] = useState(false);
  useEffect(() => {
    const hasCookie = document.cookie.includes("dankyShopFireWorks");
    const container = document.querySelector(".fireworks");

    if (!hasCookie && container) {
      setIsActive(true);
      document.cookie = "dankyShopFireWorks=true; max-age=31536000";
      const fireworks = new Fireworks(container, {
        /* options */
      });

      fireworks.start();
      container.addEventListener("click", () => {
        fireworks.stop();
        setIsActive(false);
      });
      setTimeout(() => {
        fireworks.stop();
        setIsActive(false);
      }, 10000);
    }

    return () => {
      // Zatrzymaj fajerwerki i wyczyść wszystkie zasoby (jeśli to konieczne)
    };
  }, []);
  return <div className="fireworks" id={active ? "active" : "notActive"} />;
};

export default FireWorks;
