import React, { Suspense, useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { UserContext } from "./context/UserContext.js";
import ScrollToTop from "./ScrollToTop";

// CORE
const Home = React.lazy(() => import("./pages/Core/Home/Index"));
const Shop = React.lazy(() => import("./pages/Core/Shop/Index"));
const Product = React.lazy(() => import("./pages/Core/Product/Index"));
const Basket = React.lazy(() => import("./pages/Core/Basket/Index"));
const Checkout = React.lazy(() => import("./pages/Core/Checkout/Index"));
const NotFound = React.lazy(() => import("./pages/Core/404/Index.js"));
const Privacy = React.lazy(() => import("./pages/Core/Privacy/Index.js"));
const Regulations = React.lazy(() => import("./pages/Core/Regulacy/Index.js"));
const Contact = React.lazy(() => import("./pages/Core/Contact/Index.js"));
const ThankYou = React.lazy(() => import("./pages/Core/ThankYou/Index.js"));

// ACCOUNTS
const Login = React.lazy(() => import("./pages/Accounts/Login/Index.js"));
const Password = React.lazy(() => import("./pages/Accounts/Password/Index.js"));
const Register = React.lazy(() => import("./pages/Accounts/Register/Index.js"));

// ADMIN
const AdminHome = React.lazy(() => import("./pages/Admin/Home/Index.js"));
const AdminBaner = React.lazy(() => import("./pages/Admin/Baners/Index.js"));
const AdminBanerAdd = React.lazy(() =>
  import("./pages/Admin/BanerAdd/Index.js")
);
const AdminUsers = React.lazy(() => import("./pages/Admin/Users/Index.js"));
const AdminUser = React.lazy(() => import("./pages/Admin/User/Index.js"));
const AdminOrders = React.lazy(() => import("./pages/Admin/Orders/Index.js"));
const AdminOrder = React.lazy(() => import("./pages/Admin/Order/Index.js"));
const AdminCategories = React.lazy(() =>
  import("./pages/Admin/Categories/Index.js")
);
const AdminCategoriesAdd = React.lazy(() =>
  import("./pages/Admin/CategoriesAdd/Index.js")
);
const AdminCategory = React.lazy(() =>
  import("./pages/Admin/Category/Index.js")
);
const AdminBilling = React.lazy(() => import("./pages/Admin/Billing/Index.js"));
const AdminProducts = React.lazy(() =>
  import("./pages/Admin/Products/Index.js")
);
const AdminProduct = React.lazy(() => import("./pages/Admin/Product/Index.js"));
const AdminProductAdd = React.lazy(() =>
  import("./pages/Admin/ProductAdd/Index.js")
);
const AdminAtributes = React.lazy(() =>
  import("./pages/Admin/Atributes/Index.js")
);
const AdminBrands = React.lazy(() => import("./pages/Admin/Brands/Index.js"));
const AdminSliderText = React.lazy(() =>
  import("./pages/Admin/SliderText/Index.js")
);
const AdminInfluencerAdd = React.lazy(() =>
  import("./pages/Admin/InfluencerAdd/Index.js")
);
const AdminInfluencers = React.lazy(() =>
  import("./pages/Admin/Influencers/Index.js")
);
const AdminInfluencer = React.lazy(() =>
  import("./pages/Admin/Influencer/Index.js")
);

// USER
const UserHome = React.lazy(() => import("./pages/User/Home/Index.js"));
const UserBillings = React.lazy(() => import("./pages/User/Billings/Index.js"));
const UserDelivery = React.lazy(() => import("./pages/User/Delivery/Index.js"));
const UserOrders = React.lazy(() => import("./pages/User/Orders/Index.js"));
const UserSettings = React.lazy(() => import("./pages/User/Settings/Index.js"));
const UserWishlist = React.lazy(() => import("./pages/User/Wishlist/Index.js"));

function App() {
  const authCtx = useContext(UserContext);
  return (
    <Suspense fallback={""}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {authCtx.isLoggedIn && (
            <>
              <Route path="/admin" element={<AdminHome />} />
              <Route
                path="/admin/influencerzy"
                element={<AdminInfluencers />}
              />
              <Route
                path="/admin/influencerzy/dodaj"
                element={<AdminInfluencerAdd />}
              />
              <Route
                path="/admin/influencerzy/:influ_id"
                element={<AdminInfluencer />}
              />
              <Route path="/admin/baner" element={<AdminBaner />} />
              <Route path="/admin/baner/dodaj" element={<AdminBanerAdd />} />
              <Route path="/admin/uzytkownicy" element={<AdminUsers />} />
              <Route
                path="/admin/uzytkownicy/:user_id"
                element={<AdminUser />}
              />
              <Route path="/admin/zamowienia" element={<AdminOrders />} />
              <Route
                path="/admin/zamowienia/:order_id"
                element={<AdminOrder />}
              />
              <Route path="/admin/kategorie" element={<AdminCategories />} />
              <Route
                path="/admin/kategorie/dodaj"
                element={<AdminCategoriesAdd />}
              />
              <Route
                path="/admin/kategorie/:category_id"
                element={<AdminCategory />}
              />
              <Route path="/admin/rachunki" element={<AdminBilling />} />
              <Route path="/admin/produkty" element={<AdminProducts />} />
              <Route
                path="/admin/produkty/dodaj"
                element={<AdminProductAdd />}
              />
              <Route
                path="/admin/produkty/:product_id"
                element={<AdminProduct />}
              />
              <Route path="/admin/atrybuty" element={<AdminAtributes />} />
              <Route path="/admin/marki" element={<AdminBrands />} />
              <Route path="/admin/slidertext" element={<AdminSliderText />} />

              {/* USER */}
              <Route path="/panel" element={<UserHome />} />
              <Route path="/panel/platnosci" element={<UserBillings />} />
              <Route path="/panel/dostawa" element={<UserDelivery />} />
              <Route path="/panel/zamowienia" element={<UserOrders />} />
              <Route path="/panel/ustawienia" element={<UserSettings />} />
              <Route path="/panel/wishlista" element={<UserWishlist />} />
            </>
          )}
          {/* ACCOUNTS */}
          <Route path="/zaloguj-sie" element={<Login />} />
          <Route path="/zarejestruj-sie" element={<Register />} />
          <Route path="/przypomnij-haslo" element={<Password />} />
          {/* CORE */}
          <Route index element={<Home />} />
          <Route path="/dziekuje/:order_id" element={<ThankYou />} />
          <Route path="/sklep" element={<Shop />} />
          <Route path="/sklep/:category_slug" element={<Shop />} />
          <Route path="/:product_id/:product_name" element={<Product />} />
          <Route path="/koszyk" element={<Basket />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="/polityka-prywatnosci" element={<Privacy />} />
          <Route path="/regulamin" element={<Regulations />} />
          <Route path="/kontakt" element={<Contact />} />

          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
